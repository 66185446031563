import React from 'react';
import { Box, Text, Grid, Image, Flex, Link } from 'theme-ui';
import { useProspectContext } from '../../../context/ProspectContext';
import { clickEvent } from '../../../utils/gtm';

export default function SectionYouMoreSafe() {
  const { setOpen } = useProspectContext();

  const renderTextContent = (
    title: string,
    description: string,
    btnText: string,
  ) => (
    <Flex
      sx={{
        alignSelf: 'center',
        flexDirection: 'column',
        maxWidth: '592px',
      }}>
      <Text
        as="h3"
        sx={{
          fontWeight: 'bold',
          color: 'black2business',
          fontSize: '48px',
          '@media screen and (max-width: 1400px)': {
            fontSize: '37px',
          },
          '@media screen and (max-width: 1200px)': {
            fontSize: '33px',
          },
          '@media screen and (max-width: 1024px)': {
            textAlign: 'center',
            fontSize: '29px',
          },
          '@media screen and (max-width: 768px)': {
            fontSize: '25px',
          },
        }}>
        {title}
      </Text>
      <Text
        as="p"
        mt="1rem"
        sx={{
          color: 'darkGray',
          fontSize: '22px',
          '@media screen and (max-width: 1400px)': {
            fontSize: '20px',
          },
          '@media screen and (max-width: 1200px)': {
            fontSize: '19px',
            marginTop: '0',
          },
          '@media screen and (max-width: 1024px)': {
            textAlign: 'center',
            fontSize: '18px',
          },
          '@media screen and (max-width: 768px)': {
            fontSize: '16px',
          },
        }}>
        {description}
      </Text>
      <Link
        variant="buttons.clean"
        href="/#prospect"
        onClick={() => {
          setOpen(true);
          clickEvent('clique_btn_dobra_5' + title);
        }}
        mt="3rem"
        title={btnText}
        sx={{
          paddingLeft: '0',
          display: 'flex',
          color: 'primary',
          fontWeight: 'bold',
          width: 'fit-content',
          fontSize: '18px',
          textDecoration: 'none',
          '&:hover': { textDecoration: 'underline' },
          '@media screen and (max-width: 1024px)': {
            margin: '0 auto ',
          },
        }}>
        {btnText}
        <Image ml="2" src="./right_arrow_purple.svg" />
      </Link>
    </Flex>
  );

  return (
    <Box
      id="more-safe"
      as="section"
      bg="lightGray"
      pt="6"
      sx={{
        paddingTop: '100px',
        '@media screen and (max-width: 1400px)': { padding: '9rem 1rem' },
        '@media screen and (max-width: 1024px)': { padding: '11rem 1rem' },
        '@media screen and (max-width: 960px)': { padding: '3rem 15px' },
        '@media screen and (max-width: 768px)': { padding: '40px 15px' },
        '@media screen and (max-width: 630px)': { padding: '30px 15px' },
      }}>
      <Text
        as="h1"
        sx={{
          textAlign: 'center',
          color: 'black2business',
          '@media screen and (max-width: 1400px)': { fontSize: '45px' },
          '@media screen and (max-width: 1200px)': { fontSize: '40px' },
          '@media screen and (max-width: 1024px)': { fontSize: '35px' },
          '@media screen and (max-width: 768px)': { fontSize: '30px' },
          '@media screen and (max-width: 630px)': { fontSize: '26px' },
        }}>
        Você mais seguro, mais tranquilo,
      </Text>
      <Text
        as="h1"
        sx={{
          textAlign: 'center',
          color: 'black2business',
          '@media screen and (max-width: 1400px)': { fontSize: '45px' },
          '@media screen and (max-width: 1200px)': { fontSize: '40px' },
          '@media screen and (max-width: 1024px)': { fontSize: '35px' },
          '@media screen and (max-width: 768px)': { fontSize: '30px' },
          '@media screen and (max-width: 630px)': {
            fontSize: '26px',
            marginBottom: '30px',
          },
        }}>
        mais Flix.
      </Text>
      <Box
        mt="6rem"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
          '@media screen and (max-width: 1400px)': { marginTop: '5rem' },
          '@media screen and (max-width: 1200px)': { marginTop: '4rem' },
          '@media screen and (max-width: 1024px)': { marginTop: '3rem' },
          '@media screen and (max-width: 768px)': { marginTop: '2rem' },
          '@media screen and (max-width: 630px)': { marginTop: '1rem' },
        }}>
        <Grid
          columns={[2, '1fr 1fr']}
          sx={{
            position: 'relative',
            '@media screen and (max-width: 1024px)': {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              maxWidth: '400px',
              marginBottom: '30px',
            },
          }}>
          <Box
            sx={{
              width: '100%',
              '@media screen and (max-width: 1024px)': {
                maxWidth: '200px',
                width: '100%',
                maxHeight: '150px',
              },
            }}>
            <Image
              sx={{ maxHeight: 'inherit', margin: '0 auto' }}
              src="./chair.svg"
            />
          </Box>
          {renderTextContent(
            'Contratou, usou',
            `Ninguém quer se preocupar com aquele chuveiro queimado ou vazamento na pia, né? A Flix
              resolve isso de forma rápida. Você contrata e começa a usar na hora todos os
              benefícios`,
            'Comece a usar',
          )}
        </Grid>
        <Grid
          columns={[2, '1fr 1fr']}
          sx={{
            position: 'relative',
            '@media screen and (max-width: 1024px)': {
              display: 'flex',
              flexDirection: 'column-reverse',
              alignItems: 'center',
              maxWidth: '400px',
              marginBottom: '30px',
            },
          }}>
          {renderTextContent(
            'Eu quero é assistência',
            `Nossos planos cobrem visitas de encanadores, eletricistas e chaveiros 24h por dia!`,
            'Quero experimentar',
          )}
          <Box
            sx={{
              width: '100%',
              '@media screen and (max-width: 1024px)': {
                maxWidth: '200px',
                width: '100%',
                maxHeight: '220px',
              },
            }}>
            <Image
              src="./querobis.svg"
              sx={{
                display: 'flex',
                justifySelf: 'end',
                maxHeight: 'inherit',
                margin: '0 auto',
              }}
            />
          </Box>
        </Grid>
        <Grid
          columns={[2, '1fr 1fr']}
          mb="8rem"
          sx={{
            position: 'relative',
            '@media screen and (max-width: 1400px)': {
              marginBottom: '2rem',
            },
            '@media screen and (max-width: 1200px)': {
              marginBottom: '1rem',
            },
            '@media screen and (max-width: 1024px)': {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: '0',
            },
            '@media screen and (max-width: 768px)': {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginBottom: '0',
            },
          }}>
          <Box
            sx={{
              width: '100%',
              '@media screen and (max-width: 1024px)': {
                maxWidth: '200px',
                width: '100%',
                maxHeight: '150px',
              },
            }}>
            <Image
              src="./semcarencia.svg"
              sx={{ width: '85%', display: 'flex', justifySelf: 'start' }}
            />
          </Box>

          {renderTextContent(
            'Sem carência ou multas',
            `Todos os benefícios são renovados mensalmente. Você contrata e cancela quando quiser com
            seu cartão de crédito, sem burocracias e sem estresse!`,
            'Quero seguro fácil',
          )}
        </Grid>
      </Box>
    </Box>
  );
}
