import React from 'react';
import { Box, Text, Button, Flex } from 'theme-ui';
import TopicDescription from '../TopicDescription/TopicDescription';
import geometric from '../../../public/geometric.png';
import { useProspectContext } from '../../../context/ProspectContext';
import { clickEvent } from '../../../utils/gtm';

export default function SectionEmergencial() {
  const { setOpen } = useProspectContext();

  const renderContractCard = () => (
    <Box
      bg="#fff"
      p="3rem 15px 4rem"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '384px',
        width: '100%',
        position: 'relative',
        margin: '0 auto',
        borderRadius: '3px',
        transition: '.3s linear',
        boxShadow:
          '0px 40px 64px rgba(44, 44, 82, 0.1), 0px 24px 32px rgba(44, 44, 82, 0.1), 0px 16px 16px rgba(44, 44, 82, 0.1)',
        '@media screen and (max-width: 1200px)': {
          maxWidth: '400px',
          padding: '20px 15px 25px',
        },
        '@media screen and (max-width: 630px)': {
          maxWidth: '350px',
        },
      }}>
      <Text
        as="p"
        sx={{
          fontWeight: 'bold',
          textAlign: 'center',
          color: 'primary',
          fontSize: '32px',
          '@media screen and (max-width: 768px)': {
            fontSize: '25px',
          },
          '@media screen and (max-width: 630px)': {
            fontSize: '22px',
            lineHeight: '25px',
          },
        }}>
        Contratou, usou
      </Text>
      <Text
        as="p"
        mt="3"
        sx={{
          color: 'black2business',
          textAlign: 'center',
          fontSize: '16px',
          fontWeight: 'bold',
          '@media screen and (max-width: 768px)': {
            marginTop: '10px',
          },
          '@media screen and (max-width: 630px)': {
            marginTop: '5px',
          },
        }}>
        Planos a partir de
      </Text>
      <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Text
          as="p"
          mr="2"
          color="darkGray"
          sx={{
            fontSize: '20px',
            fontWeight: 'bold',
            '@media screen and (max-width: 768px)': {
              marginRight: '10px',
              fontSize: '18px',
            },
          }}>
          R$
        </Text>
        <Text
          as="p"
          sx={{
            color: 'secondary',
            fontSize: '64px',
            fontWeight: '900',
            fontFamily: 'Lato',
            '@media screen and (max-width: 1024px)': {
              fontSize: '60px',
            },
            '@media screen and (max-width: 768px)': {
              fontSize: '55px',
              lineHeight: '60px',
            },
            '@media screen and (max-width: 630px)': {
              fontSize: '45px',
              lineHeight: '50px',
            },
          }}>
          6,40
        </Text>
        <Text
          as="p"
          ml="2"
          color="darkGray"
          sx={{ fontSize: '20px', fontWeight: 'bold' }}>
          /mês
        </Text>
      </Flex>
      <Box
        as="ul"
        p="1rem 0 1rem 5rem"
        sx={{
          '@media screen and (max-width: 1200px)': {
            maxWidth: '85%',
            width: '100%',
            margin: '0 auto',
            padding: '1rem 0',
          },
        }}>
        <Box variant="list__item.dot__colorful" as="li">
          Assinatura mensal e 100% digital
        </Box>
        <Box variant="list__item.dot__colorful" as="li">
          Sem carência ou multas
        </Box>
        <Box variant="list__item.dot__colorful" as="li">
          Casas e Apartamentos
        </Box>
        <Box variant="list__item.dot__colorful" as="li">
          Coberturas para Raio, Incêndio e Explosão
        </Box>
        <Box variant="list__item.dot__colorful" as="li">
          Cobertura de Roubo e Furto Qualificado
        </Box>
        <Box variant="list__item.dot__colorful" as="li">
          E mais: cobrimos Danos Elétricos e outras coberturas
        </Box>
      </Box>
      <Button
        title="Quero contratar"
        onClick={() => {
          setOpen(true);
          clickEvent('clique_btn_dobra_2');
        }}
        variant="buttons.primary"
        sx={{
          maxWidth: '336px',
          width: '100%',
          margin: '0 auto',
          '@media screen and (max-width: 1200px)': {
            height: '47px',
          },
        }}>
        Quero contratar
      </Button>
    </Box>
  );

  return (
    <Box
      id="emergencial"
      as="section"
      bg="lightGray"
      p="6rem 3rem"
      sx={{
        '@media screen and (max-width: 1400px)': {
          padding: '60px 15px 40px',
        },
        '@media screen and (max-width: 1200px)': {
          padding: '40px 15px 30px',
        },
        '@media screen and (max-width: 768px)': {
          padding: '25px 15px 20px',
        },
        '@media screen and (max-width: 640px)': {
          padding: '20px 15px 15px',
        },
      }}>
      <Text
        as="h4"
        sx={{
          textAlign: 'center',
          color: 'primary',
          fontSize: '40px',
          '@media screen and (max-width: 768px)': { fontSize: '20px' },
          '@media screen and (max-width: 630px)': { fontSize: '18px' },
        }}>
        Assistência emergencial 24 por 7
      </Text>
      <Flex
        sx={{
          justifyContent: 'center',
          '@media (max-width: 1200px)': { flexDirection: 'column' },
        }}>
        <Text
          as="h2"
          color="black"
          sx={{
            textAlign: 'center',
            fontSize: '56px',
            '@media screen and (max-width: 1200px)': {
              fontSize: '50px',
              lineHeight: '48px',
            },
            '@media screen and (max-width: 630px)': {
              fontSize: '32px',
              lineHeight: '35px',
            },
          }}>
          Curta sua casa,
        </Text>
        <Text
          as="h2"
          color="black"
          sx={{
            textAlign: 'center',
            fontSize: '56px',
            marginLeft: '10px',
            '@media (max-width: 1200px)': {
              lineHeight: '48px',
              fontSize: '50px',
            },
            '@media screen and (max-width: 630px)': {
              fontSize: '32px',
              lineHeight: '35px',
            },
          }}>
          nós cuidamos do resto ;&#41;
        </Text>
      </Flex>
      <Text
        as="p"
        mt="3"
        sx={{
          fontSize: '22px',
          color: 'darkGray',
          textAlign: 'center',
          '@media (max-width: 1200px)': { fontSize: '18px' },
          '@media (max-width: 768px)': {
            fontSize: '16px',
            marginTop: '5px',
          },
        }}>
        Planos essenciais para você que não quer perder tempo com reparos e
        deseja
      </Text>
      <Text
        as="p"
        sx={{
          fontSize: '22px',
          color: 'darkGray',
          textAlign: 'center',
          '@media (max-width: 1200px)': { fontSize: '18px' },
          '@media (max-width: 768px)': { fontSize: '16px' },
        }}>
        economizar mais no fim do mês.
      </Text>
      <Flex
        mt="5"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          '@media (max-width: 1200px)': {
            flexDirection: 'column-reverse',
            alignItems: 'center',
            marginTop: '30px',
          },
          '@media (max-width: 1024px)': {
            marginTop: '20px',
          },
        }}>
        <Box
          sx={{
            maxWidth: '500px',
            width: '100%',
            marginRight: '5.5rem',
            '@media (max-width: 1200px)': {
              marginRight: '0',
              marginTop: '2rem',
            },
            '@media screen and (max-width: 630px)': {
              maxWidth: '350px',
              marginTop: '1rem',
            },
          }}>
          <TopicDescription
            title="Chaveiro"
            description="Te ajudamos com dificuldades com a entrada do seu lar e até com a confecção de uma nova chave."
            imagePath="./chuveiro.svg"
          />
          <TopicDescription
            title="Encanador"
            description="Temos soluções para problemas de vazamentos e até riscos de alagamentos."
            imagePath="./encanador.svg"
          />
          <TopicDescription
            title="Eletricista"
            description="Fazemos os reparos elétricos e restabelecemos sua energia de forma simples."
            imagePath="./eletricista.svg"
          />
        </Box>
        <Box
          mb="2"
          sx={{
            maxWidth: '592px',
            width: '100%',
            height: '575px',
            background: `url("${geometric}")`,
            backgroundPosition: 'center',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            alignItems: 'center',
            '@media screen and (max-width: 1200px)': {
              height: 'unset',
            },
            '@media screen and (max-width: 630px)': {
              maxWidth: '350px',
            },
          }}>
          {renderContractCard()}
        </Box>
      </Flex>
    </Box>
  );
}
